import BaseCom from "../BaseCom";

export default class Heading extends BaseCom {
    render() {
        return (
            <section className="header">
                <div className="container section-title nopad" data-aos="fade-up">
                    <h2>{this.props.children}</h2>
                </div>
            </section>
        );
    }
}