import BaseCom from "../../BaseCom";

export default class ViewGroup extends BaseCom {
    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}