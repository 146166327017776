import BaseCom from "../BaseCom";
import Icon from "../Icon";

export default class ConfirmButton extends BaseCom {
    render() {
        return (
            <button className="btn btn-lg btn-primary d-block" type="button" onClick={this.props.onClick}>
                {this.props.icon} {this.props.children || this.props.label || "Confirm"}
            </button>
        );
    }
}