import BaseCom from "../BaseCom";
import './AppActions.css';

export default class AppActions extends BaseCom {
    render() {
        return <div className="app-actions">
            {this.props.showBack ? <button type="button" className="btn btn-lg btn-secondary d-block" onClick={this.goBack}><i className="fas fa-arrow-left"></i> Back</button> : <div></div>}
            {this.props.children}
        </div>
    }
}