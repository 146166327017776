import ViewColumn from "./Designer/ViewColumn";
import ViewContainer from "./Designer/ViewContainer";
import ViewImage from "./Designer/ViewImage";
import ViewInputText from "./Designer/ViewInputText";
import ViewSection from "./Designer/ViewSection";
import ViewText from "./Designer/ViewText";

export const ViewComponents = [
    {
        id: 'section',
        com: 'section',
        name: 'Section',
        content: 'ViewSection',
        allowedChildren: ['ViewContainer'],
        component: ViewSection
    },
    {
        id: 'container',
        com: 'container',
        name: 'Container',
        content: 'ViewContainer',
        allowedChildren: ['ViewColumn'],
        component: ViewContainer
    },
    {
        id: 'col',
        com: 'col',
        name: 'Column',
        content: 'ViewColumn',
        allowedChildren: ['ViewText', 'ViewInputText', 'ViewImage'],
        component: ViewColumn
    },
    {
        id: 'text',
        com: 'text',
        name: 'HTML',
        content: 'ViewText',
        component: ViewText
    },
    {
        id: 'input-text',
        com: 'input-text',
        name: 'Input Text',
        content: 'ViewInputText',
        component: ViewInputText
    },
    {
        id: 'image',
        com: 'image',
        name: 'Image',
        content: 'ViewImage',
        component: ViewImage
    }
];