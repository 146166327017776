import { qreq } from "../../../shared/qrequest";
import BaseCom from "../../BaseCom";
import Icon from "../../Icon";
import L from "../../Lang";
import { LayoutGlobal } from "../../Layout";
import { Pop } from "../../PopModal";
import { withParamsAndNavigate } from "../../WithParamsAndNavigate";
import AppOptions from "../AppOptions";
import DataView from "../DataView";
import View from "../Framework/View";
import OptionButton from "../OptionButton";

class ProjectList extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
    }

    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
    }

    deleteProject = async (row) => {
        Pop.showConfirm('Delete Project', 'Are you sure you want to delete this project?', async () => {
            let j = await this.apiPost('/api/project/delete', { id: row.id });
            if(j.errorCode === 0)
                this.rehash();
            else
                this.alert(j.errorMessage);
        });
    }

    render() {
        return (
            <View title="Project List">
                <AppOptions>
                    <OptionButton primary link="new"><L>Create Project</L></OptionButton>
                </AppOptions>
                <DataView
                    hash={this.state.hash}
                    api="/api/project/list"
                    actions={[
                        { label: <Icon icon="faPencil" />, onClick: (row) => this.props.navigate(String(row.id) + '/edit') },
                        { label: <Icon icon="faTrash" />, onClick: (row) => this.deleteProject(row), danger: true }
                    ]}
                    onRowClick={(row) => this.props.navigate(String(row.id))}
                    />
            </View>
        )
    }
}

export default withParamsAndNavigate(ProjectList);