import BaseCom from "../../../../BaseCom";
import FormInputV2 from "../../../../FormInputV2";
import ViewEditor from "./ViewEditor";

export default class ViewInputText extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                value: ''
            }
        };
    }
    render() {
        return (
            <ViewEditor id={this.props.id} type="entity">
                {/* TODO: embed view context for components that are not html */}
                <div id={this.props.id} view-data="input-text" view-role="component">
                    <FormInputV2 model={this.state.info} name="todo" type="text" />
                </div>
            </ViewEditor>
        );
    }
}