import BaseCom from "../../../BaseCom";
import Icon from "../../../Icon";
import L from "../../../Lang";
import { LayoutGlobal } from "../../../Layout";
import { Pop } from "../../../PopModal";
import { withParamsAndNavigate } from "../../../WithParamsAndNavigate";
import AppActions from "../../AppActions";
import AppOptions from "../../AppOptions";
import DataView from "../../DataView";
import View from "../../Framework/View";
import OptionButton from "../../OptionButton";

class EntityList extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
    }

    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
    }

    delete = async (row) => {
        Pop.showConfirm('Delete Entity', 'Are you sure you want to delete this entity?', async () => {
            let j = await this.apiPost('/api/entity/delete', { id: row.id });
            if(j.errorCode === 0)
                this.rehash();
            else
                this.alert(j.errorMessage);
        });
    }

    render() {
        return (
            <View title="Entity List">
                <AppOptions>
                    <OptionButton primary link="new"><L>Create Entity</L></OptionButton>
                </AppOptions>
                <AppActions showBack />
                <DataView
                    api={'/api/entity/list?projectID=' + this.props.params.projectID}
                    actions={[
                        { label: <Icon icon="faPencil" />, onClick: (row) => this.props.navigate(String(row.id)) },
                        { label: <Icon icon="faTrash" />, onClick: this.delete, danger: true }
                    ]}
                    onRowClick={(row) => this.props.navigate(String(row.id))}
                    hash={this.state.hash}
                    />
            </View>
        )
    }
}

export default withParamsAndNavigate(EntityList);