import BaseCom from "../../BaseCom";
import FormInputV2 from "../../FormInputV2";
import { LayoutGlobal } from "../../Layout";
import { withParamsAndNavigate } from "../../WithParamsAndNavigate";
import AppActions from "../AppActions";
import View from "../Framework/View";
import ViewGroup from "../Framework/ViewGroup";
import SaveButton from "../SaveButton";
import UForm from "../UForm";

class ProjectEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {}
        };
    }

    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
    }

    // TODO: work on this
    validator = (value) => {
        if (value.length < 3)
            return 'Identifier must be at least 3 characters';
        if(!this.isIdent(value))
            return 'Identifier must be alphanumeric or _';
        return null;
    }


    render() {
        return (
            <View title="Edit Project">
                <UForm api="/api/project"
                model={this.state.info}
                id={this.props.params.id}
                onLoad={(info) => this.setState({ info: info })}
                goBack={() => this.props.navigate(-1)}>
                    <ViewGroup>
                        <FormInputV2 model={this.state.info} name="name" label="Project Name" required />
                        <FormInputV2 model={this.state.info} name="ident" label="Project Identifier" required validator={this.validator} />
                        <FormInputV2 model={this.state.info} name="description" label="Description" type="textarea" />
                    </ViewGroup>
                    <AppActions showBack>
                        <SaveButton />
                    </AppActions>
                </UForm>
            </View>
        );
    }
}

export default withParamsAndNavigate(ProjectEdit);