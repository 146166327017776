import BaseCom from "../BaseCom";

export default class ToolHeading extends BaseCom {
    render() {
        return (
            <section className="header">
                <div className="container-fluid section-title nopad text-start" data-aos="fade-up">
                    <h3>{this.props.children}</h3>
                </div>
            </section>
        );
    }
}