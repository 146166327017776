import BaseCom from "./BaseCom";

export default class Switch extends BaseCom {

    toggle = (e) => {
        this.props.model[this.props.name] = e.target.checked;
        this.forceUpdate();
        this.props.onChange?.(e.target.checked);
    }

    render() {
        let boolValue = this.props.model?.[this.props.name];
        return (
            <div className="form-group">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id={this.props.id} checked={boolValue} onChange={this.toggle} disabled={this.props.disabled} />
                    <label className="form-check-label" htmlFor={this.props.id}>{this.props.label}</label>
                </div>
            </div>
        );
    }
}