import BaseCom from "../../../BaseCom";
import FormInputV2 from "../../../FormInputV2";
import HTMLEditor from "../../../HTMLEditor";
import Icon from "../../../Icon";
import { LayoutGlobal } from "../../../Layout";
import { ViewEditorGlobal } from "./Designer/ViewEditor";
import './ViewComponentProps.css';
import { ViewDesignerGlobal } from "./ViewDesigner";

export default class ViewComponentProps extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            entity: null,
            info: {}
        };
    }

    async componentDidMount() {
        LayoutGlobal.setConsoleMode(true);
        await this.getLoginOrExitAsync();
        await this.load(this.props.view);
    }

    async componentDidUpdate(prevProps) {
        if(this.props.view !== prevProps.view)
            await this.load(this.props.view);
    }

    load = async (view) => {
        if(!view)
            return;
        if(view.entityID)
            await this.fillItemAsync('/api/entity/item/' + view.entityID, 'entity');
    }

    removeComponent = () => {
        console.log('selected', ViewEditorGlobal.selected.props.id);
        ViewDesignerGlobal.removeComponent(ViewEditorGlobal.selected.props.id);
    }

    render() {
        return (
            <div className="p-2">
                {ViewEditorGlobal.selected?.props?.type && <div onClick={this.removeComponent} role="button" className="view-remove-component">
                    <Icon icon="faTrash" />
                </div>}
                {ViewEditorGlobal.selected?.props?.type === 'html' && <div>
                    <h4>Text Editor</h4>
                    <HTMLEditor value={ViewEditorGlobal.selected?.state?.content}
                        onChange={(html) => {
                            ViewEditorGlobal.selected?.update(html);
                        }}
                    />
                </div>}

                {ViewEditorGlobal.selected?.props?.type === 'entity' && <div>
                    {this.state.entity && <div>
                        <h4>Entity</h4>
                        <FormInputV2 model={this.state.info} name="property" label="Property" type="select" options={this.state.entity.properties} />
                    </div>}
                </div>}

                {ViewEditorGlobal.selected?.props?.type === 'image' && <div>
                    image props
                </div>}

            </div>
        );
    }
}