import React from "react";
import BaseCom from "../../../BaseCom";
import Draggable from "../../../Dragdrop/Draggable";
import Dropzone from "../../../Dragdrop/Dropzone";
import { LayoutGlobal } from "../../../Layout";
import { withParamsAndNavigate } from "../../../WithParamsAndNavigate";
import View from "../../Framework/View";
import { ViewEditorGlobal } from "./Designer/ViewEditor";
import ViewFixed from "./Designer/ViewFixed";
import ConfirmButton from "../../ConfirmButton";
import AppActions from "../../AppActions";
import L from "../../../Lang";
import Icon from "../../../Icon";
import { qreq } from "../../../../shared/qrequest";
import { ViewComponents } from "./ViewComponents";
import ViewComponentProps from "./ViewComponentProps";

export const ViewDesignerGlobal = {
    removeComponent: (id) => {}
}

class ViewDesigner extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            view: {},
            project: null,
            elements: []
        };
        this.contentRef = React.createRef();
        ViewDesignerGlobal.removeComponent = this.removeComponent;
    }

    async componentDidMount() {
        LayoutGlobal.setConsoleMode(true);
        await this.getLoginOrExitAsync();
        this.loadView();
        ViewEditorGlobal.updateFunc = () => this.forceUpdate();
        this.load(this.props.params.id);
    }

    componentWillUnmount() {
        ViewEditorGlobal.updateFunc = null;
        this.setState({ view: [], project: null, elements: [] });
    }

    loadView = async () => {
        await this.fillItemAsync('/api/view/item/' + this.props.params.id, 'view');
        await this.fillItemAsync('/api/project/item/' + this.props.params.projectID, 'project');
    }

    load = async (id) => {
        if(!id) return;
        try {
            let j = await qreq.getAsync('/api/view/item/' + id);
            if(j.errorCode === 0) {
                let elements = this.unserialize(j.item.content);
                this.setState({ item: j.item, elements: elements });
            }
            else {
                this.alert(j.errorMessage);
            }
        }
        catch(e) {
            console.error(e);
            this.unkownErrorCallback();
        }
    }

    serialize = (list, elements) => {
        if(!list)
            list = [];
        if(!elements)
            elements = this.contentRef.current.querySelectorAll(':scope > *');
        elements.forEach(i => {
            if(i.hasAttribute('view-data')) {
                // tracked element
                let type = i.getAttribute('view-data');
                let role = i.getAttribute('view-role');
                let innerData = {
                    id: i.id,
                    type: type,
                    data: (role === 'component') ? i.innerHTML : null,
                    list: []
                };

                if(type === 'dropzone' || type === 'bypass')
                    innerData.list = list;
                else
                    list.push(innerData);
                this.serialize(innerData.list, i.querySelectorAll(':scope > *'));
            }
        });
        return list;
    }

    unserializeElement = (data, children) => {
        const uuid = this.uuid();
        const component = ViewComponents.find(a => a.id === data.type);
        if(component) {
            //const dropzone = <Dropzone allowed={component.allowedChildren} children={children} />;
            const element = React.createElement(component.component, { id: uuid, key: uuid, defaultContent: data.data, allowed: component.allowedChildren }, children);
            return element;
        }
    }

    unserialize = (inputList, elements) => {
        if(!elements)
            elements = [];
        inputList.forEach(i => {
            let children = this.unserialize(i.list);
            let element = this.unserializeElement(i, children);
            elements.push(element);
        });
        return elements;
    }

    isRemoveComponentElement = (id, element) => {
        if(element.props.children) {
            this.removeComponent(id, element.props.children);
        }
        console.log('element', element.props.id);
        if(element.props.id === id) {
            console.log('FOUND', id);
            return true;
        }
        return false;
    }

    removeComponent = (id, list) => {
        let root = false;
        if(!list) {
            list = this.serialize();
            root = true;
        }
        
        list.forEach((item, idx) => {                
            if(item.id === id) {
                list.splice(idx, 1);
            }
            else if(item.list)
                this.removeComponent(id, item.list);
        });
        
        if(root) {
            console.log('data', list);
            let elements = this.unserialize(list);
            this.setState({ elements: elements  });
        }
    }

    getData = () => {
        let data = this.serialize();
        console.log('data', data);
        let elements = this.unserialize(data);
        console.log('elements', elements);

        // clear elements to test
        this.setState({ elements: [] });

        // set elements back
        setTimeout(() => {
            this.setState({ elements: elements });
        }, 500);
        return data;
    }

    save = async () => {
        try {
            let data = {
                ...this.state.view,
                id: this.props.params.id ?? 0,
                projectID: this.state.project.id,
                content: this.getData()
            };
            let j = await qreq.postAsync('/api/view/save', data);
            if(j.errorCode === 0) {
                this.success(<L>Saved!</L>);
                if(!this.props.params.id)
                    this.props.navigate(String(j.item.id));
            }
            else
                this.alert(j.errorMessage);
        }
        catch(e) {
            console.error(e);
            this.unkownErrorCallback();
        }
    }

    render() {
        if (!this.state.view || !this.state.project)
            return null;
        return (
            <View expandHeight noSectionPadding>
                <AppActions showBack>
                    <ConfirmButton onClick={() => this.save()} icon={<Icon icon="faSave" />}><L>Save</L></ConfirmButton>
                </AppActions>
                <ViewFixed top="75px" left="0" bottom="0" width="20rem"
                    style={{ backgroundColor: '#eee'}}>

                    {ViewComponents.map((i,idx) => <Draggable key={idx} id={i.id} com={i.com} content={i.content}>
                        <div>{i.name}</div>
                    </Draggable>)}


                </ViewFixed>
                <ViewFixed top="75px" left="20rem" right="20rem" bottom="0">
                    <Dropzone refPtr={this.contentRef} allowed={[ 'ViewSection' ]} children={this.state.elements}>
                        
                    </Dropzone>
                </ViewFixed>
                <ViewFixed top="75px" right="0" bottom="0" width="20rem"
                    style={{ backgroundColor: '#eee'}}>

                    <ViewComponentProps view={this.state.view} />
                </ViewFixed>
            </View>
        )
    }
}

export default withParamsAndNavigate(ViewDesigner);