import { qreq } from "../../shared/qrequest";
import BaseCom from "../BaseCom";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";

class UForm extends BaseCom {

    async componentDidMount() {
        if(this.props.id)
            await this.load(this.props.id);
    }

    load = async (id) => {
        try {
            let j = await qreq.getAsync(this.props.api + '/item/' + id);
            if(j.errorCode === 0) {
                this.props.onLoad?.(j.item);
            }
            else {
                this.props.onError?.(j);
                this.props.onLoad?.({});
            }
        }
        catch(e) {
            console.error(e);
            this.props.onError?.({ errorCode: -1, message: e.message });
            this.unkownErrorCallback();
            this.props.onLoad?.({});
        }
    }
    
    submit = async (e) => {
        e?.preventDefault();
        this.props.onBeforeSubmit?.(this.props.model);
        try {
            let j = await qreq.postAsync(this.props.api + '/save', this.props.model);
            if(j.errorCode === 0) {
                this.props.onSuccess?.(j);
                if(this.props.goBack)
                    this.props.navigate(-1);
            }
            else {
                this.alert(j.errorMessage);
                this.props.onError?.(j);
            }
        }
        catch(e) {
            console.error(e);
            this.props.onError?.({ errorCode: -1, message: e.message });
            this.unkownErrorCallback();
        }
    }

    render() {
        return (
            <form onSubmit={this.submit}>
                {this.props.children}
            </form>
        );
    }
}

export default withParamsAndNavigate(UForm);