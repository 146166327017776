import BaseCom from "../../../BaseCom";
import FormInputV2 from "../../../FormInputV2";
import { LayoutGlobal } from "../../../Layout";
import { withParamsAndNavigate } from "../../../WithParamsAndNavigate";
import AppActions from "../../AppActions";
import View from "../../Framework/View";
import SaveButton from "../../SaveButton";
import UForm from "../../UForm";

class ViewEdit extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            info: {
                projectID: this.props.params.projectID
            }
        };
    }

    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
    }


    beforeSubmit = (data) => {

    }

    render() {
        return (
            <View title="Edit View">
                <UForm api="/api/view"
                    onBeforeSubmit={this.beforeSubmit}
                    model={this.state.info}
                    id={this.props.params.id}
                    onLoad={(info) => this.setState({ info: info })}
                    goBack={() => this.props.navigate(-1)}>

                    <FormInputV2 model={this.state.info} label="Name" name="name" required />
                    <FormInputV2 model={this.state.info} label="Ident" name="ident" required />
                    <FormInputV2 model={this.state.info} label="Description" name="description" type="textarea" />

                    <FormInputV2 model={this.state.info} label="Entity" name="entityID" type="select" api={`/api/entity/list?projectID=${this.props.params.projectID}`} />

                    <AppActions showBack>
                        <SaveButton />
                    </AppActions>
                </UForm>
            </View>
        )
    }
}

export default withParamsAndNavigate(ViewEdit);