import BaseCom from "../../../../BaseCom";
import './ViewEditor.css';

export const ViewEditorGlobal = {
    selected: null,
    updateFunc: null
}

export default class ViewEditor extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            content: ''
        }
    }

    componentDidMount() {
        this.setState({ id: this.uuid(), content: this.props.defaultContent });
    }

    componentWillUnmount() {
        if (ViewEditorGlobal.selected === this) {
            ViewEditorGlobal.selected = null;
        }
    }

    clearCurrentSelection = () => {
        let selected = ViewEditorGlobal.selected;
        ViewEditorGlobal.selected = null;
        selected.forceUpdate();
    }

    click = (e) => {
        e.stopPropagation();
        console.log('click', this.props.id, this.props.type);
        if (ViewEditorGlobal.selected) {
            this.clearCurrentSelection();
        }
        ViewEditorGlobal.selected = this;
        ViewEditorGlobal.updateFunc?.();
        this.forceUpdate();
    }

    update = (content) => {
        this.setState({ content: content });
    }

    render() {
        this.tag = 'div';
        if (this.props.type === 'html') {
            return (
                <div id={this.props.id} view-data="text" view-role="component" onClick={this.click}
                className={ViewEditorGlobal.selected === this ? 'ViewEditor selected' : 'ViewEditor'}
                >
                    <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
                </div>
            );
        }
        else {
            return (
                <this.tag id={this.props.id} view-data={this.props.viewData || 'bypass'} view-role={this.props.viewRole || 'bypass'} onClick={this.click}
                    className={(ViewEditorGlobal.selected === this ? 'ViewEditor selected ' : 'ViewEditor ') + (this.props.className || '')}
                    >
                    {this.props.children}
                </this.tag>
            );
        }
    }
}