import { getLang } from "../shared/GLang";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Switch from "./Switch";

export default class FormInputV2 extends BaseCom {

    async componentDidMount() {
        if (this.props.type === 'select') {
            await this.loadOptions();
        }
    }

    loadOptions = async () => {
        if(!this.props.api)
            return;
        try {
            let j = await qreq.getAsync(this.props.api);
            if (j.errorCode === 0) {
                this.setState({ options: j.list });
                this.forceUpdate();
            }
            else {
                this.alert(j.errorMessage);
            }
        }
        catch (e) {
            console.error(e);
            this.unkownErrorCallback();
        }
    }

    change = (e) => {
        this.props.model[this.props.name] = e.target.value;
        this.forceUpdate();
    }

    toggle = (e) => {
        this.props.model[this.props.name] = e.target.checked;
        this.forceUpdate();
    }

    render() {
        let options = this.state.options || this.props.options;
        if (this.props.type === 'switch') {
            
            return (
                <Switch model={this.props.model} name={this.props.name} label={this.props.label} />
            );
        }
        return (
            <div className="form-group">
                {this.props.label && <label className="form-label">{this.props.required ? '*' : ''} {getLang(this.props.label)}</label>}
                <div className="input-group">
                    {
                    
                    
                    this.props.type === 'select' ? (
                        <select className="form-select" value={this.props.model?.[this.props.name] || ''} onChange={this.change} required={this.props.required}>
                            <option value="">{getLang((this.props.required ? '* ' : '') + ('Select ' + (this.props.label || this.props.placeholder || '')))}</option>
                            {options?.map((i, idx) => <option key={idx} value={i.id}>{getLang(i.name)}</option>)}
                        </select>

                    ) :
                    
                    
                    this.props.type === 'textarea' ? (
                        <textarea className="form-control" value={this.props.model?.[this.props.name] || ''} onChange={this.change} placeholder={(this.props.placeholder ? (this.props.required ? '* ' : '') + this.props.placeholder : '')} required={this.props.required} />
                    )
                        :
                        (
                            <input type={this.props.type || 'text'} className="form-control" value={this.props.model?.[this.props.name] || ''} onChange={this.change} placeholder={(this.props.placeholder ? (this.props.required ? '* ' : '') + this.props.placeholder : '')} required={this.props.required} />
                        )}
                </div>
            </div>
        )
    }
}