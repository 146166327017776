import BaseCom from "../../../../BaseCom";
import ViewEditor from "./ViewEditor";
import Dropzone from "../../../../Dragdrop/Dropzone";

export default class ViewContainer extends BaseCom {
    render() {
        return (
            <ViewEditor tag="div" type="group" id={this.props.id} viewData="container" className={this.props.fluid ? 'container-fluid' : 'container'}>
                <Dropzone allowed={this.props.allowed} children={this.props.children} className="row" view-data="bypass" />
            </ViewEditor>
        );
    }
}