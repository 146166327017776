import BaseCom from "../../../BaseCom";
import FormInputV2 from "../../../FormInputV2";
import { LayoutGlobal } from "../../../Layout";
import ViewGroup from "../../Framework/ViewGroup";
import { withParamsAndNavigate } from "../../../WithParamsAndNavigate";
import AppActions from "../../AppActions";
import View from "../../Framework/View";
import SaveButton from "../../SaveButton";
import UForm from "../../UForm";

class AccessRoleEdit extends BaseCom {
    
        constructor(props) {
            super(props);
            this.state = {
                info: {
                    projectID: this.props.params.projectID
                }
            };
        }
    
        async componentDidMount() {
            await this.getLoginOrExitAsync();
            LayoutGlobal.setConsoleMode(true);
        }

        render() {
            return (
                <View title="Edit Role">
                    <UForm api="/api/access-role"
                        model={this.state.info}
                        id={this.props.params.id}
                        onLoad={(info) => this.setState({ info: info })}
                        goBack={() => this.props.navigate(-1)}>
                        
                        <ViewGroup>
                            <FormInputV2 model={this.state.info} name="name" label="Role Name" required />
                            <FormInputV2 model={this.state.info} name="ident" label="Identity" required />
                            <FormInputV2 model={this.state.info} name="description" label="Description" />
                        </ViewGroup>
                        
                        <AppActions showBack>
                            <SaveButton />
                        </AppActions>
                    </UForm>
                </View>
            );
        }

}

export default withParamsAndNavigate(AccessRoleEdit);