import BaseCom from "../../../../BaseCom";
import ViewEditor from "./ViewEditor";

export default class ViewText extends BaseCom {

    render() {
        return (
            <ViewEditor id={this.props.id} type="html" defaultContent={this.props.defaultContent || 'HTML'} />
        );
    }
}