import BaseCom from "../../../../BaseCom";

export default class ViewFixed extends BaseCom {
    render() {
        let style = this.props.style || {};
        return (
            <div id={this.props.id} view-data="fixed" className="position-fixed"
                style={{
                    ...style,
                    top: this.props.top || 'auto',
                    left: this.props.left || 'auto',
                    right: this.props.right || 'auto',
                    bottom: this.props.bottom || 'auto',
                    overflow: this.props.overflow || 'auto',
                    width: this.props.width || 'auto',
                    zIndex: this.props.zIndex || 1000
                }}>
                {this.props.children}
            </div>
        )
    }
}