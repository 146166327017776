import BaseCom from "../BaseCom";
import Icon from "../Icon";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";

class OptionButton extends BaseCom {
    click = () => {
        if(this.props.link) {
            this.props.navigate(this.props.link);
        }
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <button className={'btn btn-lg ' + (this.props.primary ? 'btn-primary' : 'btn-secondary')} type="button" onClick={this.click}>
                {this.props.icon && <Icon icon={this.props.icon} />} {this.props.children}
            </button>
        );
    }
}

export default withParamsAndNavigate(OptionButton);