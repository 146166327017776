import BaseCom from "../../../../BaseCom";
import ViewEditor from "./ViewEditor";
import Dropzone from "../../../../Dragdrop/Dropzone";

export default class ViewSection extends BaseCom {
    render() {
        return (
            <ViewEditor tag="section" type="group" id={this.props.id} viewData="section">
                <Dropzone allowed={this.props.allowed} children={this.props.children} view-data="bypass" />
            </ViewEditor>
        );
    }
}

