import BaseCom from "../../../../BaseCom";
import ViewEditor from "./ViewEditor";

export default class ViewImage extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                value: ''
            }
        };
    }

    render() {
        return (
            <ViewEditor id={this.props.id} type="image">
                <div id={this.props.id} view-data="image" view-role="component">
                    <img src="/assets/img/placeholder.png" alt="" />
                </div>
            </ViewEditor>
        );
    }
}