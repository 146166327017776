import BaseCom from "../../../BaseCom";
import FormInputV2 from "../../../FormInputV2";
import { LayoutGlobal } from "../../../Layout";
import MultiSelect from "../../../MultiSelect";
import ViewGroup from "../../Framework/ViewGroup";
import { withParamsAndNavigate } from "../../../WithParamsAndNavigate";
import AppActions from "../../AppActions";
import View from "../../Framework/View";
import SaveButton from "../../SaveButton";
import UForm from "../../UForm";

class AccessPolicyEdit extends BaseCom {
    
        constructor(props) {
            super(props);
            this.state = {
                info: {
                    projectID: this.props.params.projectID,
                    permissions: []
                }
            };
        }
    
        async componentDidMount() {
            await this.getLoginOrExitAsync();
            LayoutGlobal.setConsoleMode(true);
            await this.fillListAsync('/api/entity/list?projectID=' + this.props.params.projectID, 'entityList');
            await this.fillListAsync('/api/access-permission/list?projectID=' + this.props.params.projectID, 'permissionList');
        }

        beforeSubmit = (data) => {
            data.permissions = data.permissions.filter(i => i.selected);
        }

        permissionsChanged = (list) => {
            this.setState({ info: {...this.state.info, permissions: list } });
        }

        render() {
            return (
                <View title="Edit Policy">
                    <UForm api="/api/access-policy"
                        model={this.state.info}
                        id={this.props.params.id}
                        onLoad={(info) => this.setState({ info: info })}
                        onBeforeSubmit={this.beforeSubmit}
                        goBack={() => this.props.navigate(-1)}>
    
                        <ViewGroup>
                            <FormInputV2 model={this.state.info} name="name" label="Policy Name" required />
                            <FormInputV2 model={this.state.info} name="ident" label="Identity" required />
                            <FormInputV2 model={this.state.info} name="description" label="Description" />
                            <FormInputV2 model={this.state.info} name="entityID" label="Entity" type="select" required options={this.state.entityList} />
                        </ViewGroup>

                        <MultiSelect
                        label="Permissions"
                        initialValues={this.state.info.permissions}
                        options={this.state.permissionList}
                        onChange={this.permissionsChanged}
                        />
                        
                        <AppActions showBack>
                            <SaveButton />
                        </AppActions>
                    </UForm>
                </View>
            );
        }

}

export default withParamsAndNavigate(AccessPolicyEdit);