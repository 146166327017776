import BaseCom from "../../../BaseCom";
import FormInputV2 from "../../../FormInputV2";
import Icon from "../../../Icon";
import L from "../../../Lang";
import { LayoutGlobal } from "../../../Layout";
import ViewGroup from "../../Framework/ViewGroup";
import { withParamsAndNavigate } from "../../../WithParamsAndNavigate";
import AppActions from "../../AppActions";
import View from "../../Framework/View";
import OptionButton from "../../OptionButton";
import SaveButton from "../../SaveButton";
import UForm from "../../UForm";

class EntityEdit extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            info: {
                projectID: this.props.params.projectID
            }
        };
    }

    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
        await this.fillListAsync('/api/property/list', 'propertyList');
    }


    addProperty = () => {
        let props = this.state.info.properties ? [...this.state.info.properties] : [];
        props.push({});
        this.setState({ info: { ...this.state.info, properties: props } });
    }

    deleteProperty = (idx) => {
        let props = this.state.info.properties ? [...this.state.info.properties] : [];
        props.splice(idx, 1);
        this.setState({ info: { ...this.state.info, properties: props } });
    }

    beforeSubmit = (data) => {
        data.properties?.forEach(i => {
            if (i.typeID)
                i.typeID = parseInt(i.typeID);
        });
    }

    render() {
        return (
            <View title="Edit Entity">
                <UForm api="/api/entity"
                    onBeforeSubmit={this.beforeSubmit}
                    model={this.state.info}
                    id={this.props.params.id}
                    onLoad={(info) => this.setState({ info: info })}
                    goBack={() => this.props.navigate(-1)}>

                    <ViewGroup>
                        <FormInputV2 model={this.state.info} name="name" label="Entity Name" required />
                        <FormInputV2 model={this.state.info} name="ident" label="Identity" required />
                        <FormInputV2 model={this.state.info} name="description" label="Description" />
                    </ViewGroup>

                    <ViewGroup>
                        {this.state.info?.properties?.map((i, idx) => <div key={idx} className="d-flex align-items-center gap-2">
                            <FormInputV2 model={i} name="name" placeholder="Property Name" required />
                            <FormInputV2 model={i} name="typeID" type="select" placeholder="Type" options={this.state.propertyList} required />
                            <FormInputV2 model={i} name="defaultValue" placeholder="Default Value" />
                            <FormInputV2 model={i} name="isRequired" label="Required" type="switch" />
                            <FormInputV2 model={i} name="userCanRead" label="User Read" type="switch" />
                            <FormInputV2 model={i} name="userCanWrite" label="User Write" type="switch" />
                            <button type="button" className="btn btn-danger" onClick={() => this.deleteProperty(idx)}><Icon icon="faTrash" /></button>
                        </div>)}
                        <OptionButton onClick={this.addProperty}><L>Add Property</L></OptionButton>
                    </ViewGroup>

                    <AppActions showBack>
                        <SaveButton />
                    </AppActions>
                </UForm>
            </View>
        )
    }
}

export default withParamsAndNavigate(EntityEdit);