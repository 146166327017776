import BaseCom from "../BaseCom";
import Icon from "../Icon";

export default class SaveButton extends BaseCom {
    render() {
        return (
            <button className="btn btn-lg btn-success d-block" type="submit">
                <Icon icon="faSave" /> {this.props.label || "Save"}
            </button>
        );
    }
}