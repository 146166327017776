import BaseCom from "../../../BaseCom";
import FormInput from "../../../FormInput";
import FormInputV2 from "../../../FormInputV2";
import { LayoutGlobal } from "../../../Layout";
import { withParamsAndNavigate } from "../../../WithParamsAndNavigate";
import AppActions from "../../AppActions";
import View from "../../Framework/View";
import SaveButton from "../../SaveButton";
import UForm from "../../UForm";

class ModuleEdit extends BaseCom {
    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
    }

    render() {
        return (
            <View title="Edit Module">
                <UForm api="/api/module"
                model={this.state.info}
                id={this.props.params.id}
                onLoad={(info) => this.setState({ info: info })}
                goBack={() => this.props.navigate(-1)}>
                    <FormInputV2 model={this.state.info} name="name" label="Module Name" required />

                    <AppActions showBack>
                        <SaveButton />
                    </AppActions>
                </UForm>
            </View>
        )
    }
}

export default withParamsAndNavigate(ModuleEdit);