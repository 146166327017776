import BaseCom from "../BaseCom";

export const DraggableGlobal = {
    draggables: [],
    getDraggableByID: (id) => DraggableGlobal.draggables.find(d => d.id === id)
};

export default class Draggable extends BaseCom {

    componentDidMount() {
        DraggableGlobal.draggables.push({
            id: this.props.id,
            content: this.props.content
        });
    }


    componentWillUnmount() {
        DraggableGlobal.draggables = DraggableGlobal.draggables.filter(d => d.id !== this.props.id);
    }

    dragStart = (e) => {
        console.log('dragStart', e);

        let draggable = DraggableGlobal.getDraggableByID(e.target.id);

        if(!draggable)
            return;


        let data = {
            id: e.target.id,
            com: {...this.props.com},
            move: this.props.move
        };

        delete data.component;

        
        e.dataTransfer.setData('text/json', JSON.stringify(data));
        e.dataTransfer.dropEffect = 'move';
    }

    dragEnd = (e) => {
        console.log('dragEnd', e);
    }

    render() {
        return (
            <div className="dragdrop-draggable"
            view-data="bypass"
            view-role="bypass"
            id={this.props.id || this.uuid()}
            draggable
            onDragStart={this.dragStart}
            onDragEnd={this.dragEnd}
            style={{ cursor: 'move' }}
            >
                {this.props.children}
            </div>
        );
    }
}