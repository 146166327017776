import BaseCom from "../BaseCom";
import { DraggableGlobal } from "./Draggable";
import { ViewComponents } from "../Solusek/Project/View/ViewComponents";
import React from "react";
import './Dropzone.css';


export default class Dropzone extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            children: []
        };

    }

    componentDidMount() {
        let children = this.props.children || [];
        this.setState({children: [...children]});
        this.props.onChildrenChange?.(children);
    }

    componentWillUnmount() {
        this.setState({children: []});
        this.props.onChildrenChange?.([]);
    }

    componentDidUpdate(prevProps) {
        if(this.props.children !== prevProps.children) {
            let children = this.props.children || [];
            this.setState({children: [...children]});
            this.props.onChildrenChange?.(children);
        }
    }

    dragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    }

    dragDrop = (e) => {
        e.preventDefault();
        const json = e.dataTransfer.getData('text/json');


        const data = JSON.parse(json);


        const id = String(data.id);
        


        console.log('dragDrop', id);

        const draggable = DraggableGlobal.getDraggableByID(id);

        if(!draggable || !draggable.content)
            return;

        if(this.props.allowed.indexOf(draggable.content) === -1)
            return;

        let children = [...this.state.children];
        if(data.move) {
            console.log('move',data.move,children.find(a => a.props.move === data.move));
            children = children.filter(a => a.props.move !== data.move);
        }


        this.pushContent(draggable, children);

        this.setState({children:children});
        this.props.onChildrenChange?.(this.state.children);
    }

    pushContent = (draggable, children, componentChildren) => {

        const uuid = this.uuid();

        let component = ViewComponents.find(a => a.content === draggable.content);

        if(component) {
            componentChildren = componentChildren || [];

            const element = React.createElement(component.component, { id: uuid, key: uuid, allowed: component.allowedChildren }, componentChildren);

            
            children.push(element);
        }

        

        
    }

    render() {

        return (
            <div className={'dragdrop-dropzone' + (this.props.className ? ' ' + this.props.className : '')}
            onDragOver={this.dragOver}
            onDrop={this.dragDrop}
            ref={this.props.refPtr}
            view-data="dropzone">
                {this.state.children}
            </div>
        );
    }
}