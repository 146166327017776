import BaseCom from "../../../../BaseCom";
import ViewEditor from "./ViewEditor";
import Dropzone from "../../../../Dragdrop/Dropzone";

export default class ViewColumn extends BaseCom {
    render() {
        return (
            <ViewEditor tag="div" type="group" id={this.props.id} viewData="col" className={'col' + (this.props.size ? '-' + this.props.size : '')}>
                <Dropzone allowed={this.props.allowed} children={this.props.children} view-data="bypass" />
            </ViewEditor>
        )
    }
}