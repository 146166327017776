import BaseCom from "../../BaseCom";
import ToolHeading from "../ToolHeading";
import './View.css';

export default class View extends BaseCom {
    render() {
        return <section className={this.props.noSectionPadding ? 'no-pad' : ''}>
            {this.props.title && <ToolHeading>{this.props.title}</ToolHeading>}
            <div className={'container-fluid' + (this.props.expandHeight ? ' expand-height' : '')}>
                {this.props.children}
            </div>
        </section>
    }
}