import BaseCom from "../../BaseCom";
import Icon from "../../Icon";
import { LayoutGlobal } from "../../Layout";
import { withParamsAndNavigate } from "../../WithParamsAndNavigate";
import AppActions from "../AppActions";
import AppOptions from "../AppOptions";
import ConfirmButton from "../ConfirmButton";
import View from "../Framework/View";
import OptionButton from "../OptionButton";

class ProjectView extends BaseCom {
    async componentDidMount() {
        await this.getLoginOrExitAsync();
        LayoutGlobal.setConsoleMode(true);
        await this.fillItemAsync('/api/project/item/' + this.props.params.id, 'item');
    }

    build = async () => {
        let j = await this.apiPost('/api/build/' + this.state.item.id, {});
        if(!j) return;
        if (j.errorCode === 0)
            this.success('Build started');
        else
            this.alert(j.errorMessage);
    }

    render() {
        return (
            <View title={this.state.item?.name}>
                <AppOptions>
                    <ConfirmButton icon={<Icon icon="faBuilding" />} label="Build" onClick={this.build} />
                </AppOptions>
                <AppActions showBack />                
                <div className="row text-center">
                    <div className="col-md">
                        <OptionButton link="entity">Entities</OptionButton>
                    </div>
                    <div className="col-md">
                        <OptionButton link="module">Modules</OptionButton>
                    </div>
                    <div className="col-md">
                        <OptionButton link="controller">Controllers</OptionButton>
                    </div>
                    <div className="col-md">
                        <OptionButton link="access-role">Roles</OptionButton>
                    </div>
                    <div className="col-md">
                        <OptionButton link="access-policy">Policies</OptionButton>
                    </div>
                    <div className="col-md">
                        <OptionButton link="view">Views</OptionButton>
                    </div>
                </div>
            </View>
        )
    }
}

export default withParamsAndNavigate(ProjectView);