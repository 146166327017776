import BaseCom from "./BaseCom";
import Switch from "./Switch";

export default class MultiSelect extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            options: []
        };
    }

    componentDidMount() {
        this.setup(this.props.options);
    }

    componentDidUpdate(prevProps) {
        if(this.props.options !== prevProps.options) {
            this.setup(this.props.options);
        }
    }

    setup = (options) => {
        let initialValues = this.props.initialValues;
        if(!initialValues || !options) return;
        options = [...options];
        for (let i = 0; i < options.length; i++) {
            options[i].selected = initialValues?.findIndex(x => x.id === options[i].id) !== -1;
        }
        this.setState({ options: options });
        this.props.onChange?.(options);
    }

    change = (selected, idx) => {
        let options = [...this.state.options];
        options[idx].selected = selected;
        this.setState({ options: options });
        this.props.onChange?.(options);
    }

    render() {
        return (
            <div className="form-group">
                <ul className="list-group">
                    {this.state.options?.map((opt, idx) => (
                        <li key={idx} className="list-group-item">
                            <Switch model={opt} name={'selected'} label={opt.name} onChange={(on) => this.change(on, idx)} />
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}