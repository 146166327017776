import { qreq } from "../../shared/qrequest";
import BaseCom from "../BaseCom";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";

class DataView extends BaseCom {

    async componentDidMount() {
        await this.fillDataAsync();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.hash !== prevProps.hash)
            await this.fillDataAsync();
    }

    async fillDataAsync() {
        try {
            let j = await qreq.getAsync(this.props.api);
            if (j.errorCode === 0) {
                this.setState({ list: j.list, columns: j.dict });
            }
            else
                this.alert(j.errorMessage);
        }
        catch {
            this.unkownErrorCallback();
        }
    }

    rowClick = (row) => {
        if (this.props.rowLink) {
            if(this.props.rowLink === true)
                this.props.navigate(String(row.id));
            else
                this.props.navigate(this.props.rowLink.replace(':id', row.id));
        }
        this.props.onRowClick?.(row);
    }

    clickable = () => {
        return this.props.rowLink || this.props.onRowClick;
    }

    render() {
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        {this.state.columns?.map((i, idx) => <th key={idx}>{i.label}</th>)}
                        {this.props.actions && <th className="table-actions"></th>}
                    </tr>
                </thead>
                <tbody>
                    {this.state.list?.map((row, rowIdx) => <tr key={rowIdx} role={this.clickable() && 'button'}>
                        {this.state.columns?.map((col, colIdx) => <td key={colIdx}
                                                                  onClick={() => this.rowClick(row)}>
                                                                    {row[col.name]}
                                                                  </td>)}
                        {this.props.actions && <td className="table-actions">
                            {this.props.actions.map((action, actionIdx) => (
                                <button key={actionIdx} className={'btn btn-sm ' + (action.primary ? 'btn-primary'
                                : action.danger ? 'btn-danger' : 'btn-secondary')}
                                onClick={() => action.onClick(row)}>
                                    {action.label}
                                </button>
                            ))}
                            </td>}                            
                    </tr>)}
                </tbody>
            </table>
        )
    }
}

export default withParamsAndNavigate(DataView);